<template>
    <b-container>
        <b-row>
            <b-col cols="12"
                   v-for="(row, index) in localRows"
                   :key="index + 1" v-html="row.getHtml()"
                   :class="classBox"
            />
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "UImgBox",
        props: {
            classBox: {
                type: String,
                default: '',
            },
            rows: {
                type: Array,
                default() {
                    return [];
                }
            },
        },
        data() {
            return {
                localRows: this.rows,
            }
        }
    }
</script>
